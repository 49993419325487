import { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  AccordionDetails,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Popper,
  styled,
} from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Option } from 'src/components/RHF/Dropdown/Dropdown';
import {
  ApprovalUsers,
  SubApproval,
  useGetAddRevisorFormQuery,
} from 'src/features/Approvals/Approvals.service';
import {
  ExternalUserRevisor,
  GroupRevisor,
  UserRevisor,
} from 'src/features/JobForm/JobForm.service';
import { isEmailAddressValid } from 'src/utilities/helperFunctions';
import { useAppSelector, useRouteParams } from 'src/utilities/hooks';

type AddRevisorFormProps = {
  activeApproval?: SubApproval | SubApproval[];
  isNewApproval?: boolean;
  loopId?: number;
  revisorAction?: string;
  onSubmit:
    | ((addRevisorPayload: AddRevisorPayload) => Promise<void>)
    | ((addRevisorPayload: AddRevisorPayload) => void);
  templateId?: number;
};
type AddRevisorFormData = {
  duration: Option | null;
  externalUsers: string[];
  filterGroup: Option | null;
  groups: string | string[];
  position: Option | null;
  prefixes: Option | null;
  users: string | string[];
};
export type AddRevisorPayload =
  | AddRevisorToExistingApprovalPayload
  | AddRevisorToNewApprovalPayload;
export type AddRevisorToExistingApprovalPayload = {
  duration?: string | number;
  externalUsers?: string[];
  groups?: string[];
  position?: string | number;
  prefixes: string;
  users?: string[];
};
export type AddRevisorToNewApprovalPayload = {
  externalUsers?: ExternalUserRevisor[];
  groups?: GroupRevisor[];
  users?: UserRevisor[];
};

const StyledPopper = styled(Popper)`
  z-index: 100001 !important;
`;

export function AddRevisorForm({
  activeApproval,
  isNewApproval = false,
  loopId,
  onSubmit,
  revisorAction,
  templateId,
}: AddRevisorFormProps) {
  const { age, jobId, jobType } = useRouteParams();
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.details.id);
  const REQUIRED_RULE = { required: { message: `${t('lib.required', 'Required')}`, value: true } };

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit: rhfSubmit,
    reset,
    watch,
  } = useForm<AddRevisorFormData>({
    defaultValues: {
      duration: null,
      externalUsers: [],
      filterGroup: null,
      groups: [],
      position: null,
      prefixes: null,
      users: [],
    },
  });

  const {
    data: { duration, groups, positions, prefixes, rawGroups, rawUsers, users } = {
      duration: [],
      groups: [],
      positions: [],
      prefixes: [],
      rawGroups: {},
      rawUsers: {},
      users: [],
    },
    isFetching,
  } = useGetAddRevisorFormQuery(
    { age, jobid: jobId, loopId, src: jobType, templateId },
    { skip: !templateId && !loopId },
  );

  const filterGroup = watch('filterGroup');

  const isRequired = {
    isRequired: (_: unknown, { externalUsers, groups, users }: AddRevisorFormData) =>
      !!externalUsers.length ||
      !!groups.length ||
      !!users.length ||
      'At least one user, group or external user must be selected.',
  };
  const shouldRenderPositionAndDay = revisorAction !== 'expand' && revisorAction !== 'forward';

  function handleClickReset() {
    reset();
  }

  async function handleSubmit({
    duration,
    externalUsers,
    groups,
    position,
    prefixes,
    users,
  }: AddRevisorFormData) {
    if (isNewApproval) {
      const _duration = parseInt((duration as Option).value);
      const _position = parseInt((position as Option).value);

      onSubmit({
        ...(!!externalUsers.length && {
          externalUsers: externalUsers.map((externalUser) => {
            return {
              ad_hoc: true,
              checkbox: { checked: true },
              duration: _duration,
              email: externalUser,
              email_attachments: [],
              event_action_id: 0,
              invited: true,
              position: _position,
              send_email: true,
              task: '',
              type: 'email_ext' as const,
            };
          }),
        }),
        ...(!!groups.length && {
          groups: (groups as string).split(',').map((group) => {
            const usersInGroup: ApprovalUsers = {};

            rawGroups[parseInt(group)].users.forEach((user) => {
              usersInGroup[user] = rawUsers[user];
            });

            return {
              ad_hoc: true,
              checkbox: { checked: true },
              confirm: 'one' as const,
              duration: _duration,
              email_attachments: [],
              event_action_id: 0,
              group: rawGroups[parseInt(group)].name,
              group_id: parseInt(group),
              invited: true,
              position: _position,
              send_email: true,
              task: '',
              type: 'email_gru' as const,
              users: usersInGroup,
            };
          }),
        }),
        ...(!!users.length && {
          users: (users as string).split(',').map((user) => {
            return {
              ad_hoc: true,
              checkbox: { checked: true },
              duration: _duration,
              email_attachments: [],
              event_action_id: 0,
              invited: true,
              position: _position,
              send_email: true,
              task: '',
              type: 'email_usr' as const,
              user_id: parseInt(user),
              username: rawUsers[parseInt(user)],
            };
          }),
        }),
      });
    } else {
      const currentApproval = !shouldRenderPositionAndDay
        ? Array?.isArray(activeApproval)
          ? activeApproval[0]?.details
          : (activeApproval as SubApproval)?.details
        : [];
      const currentRevisor = currentApproval?.find((approval) => approval.user_id === userId);

      await onSubmit({
        duration: shouldRenderPositionAndDay ? (duration as Option).value : currentRevisor?.days,
        position: shouldRenderPositionAndDay
          ? (position as Option).value
          : currentRevisor?.position,
        prefixes: (prefixes as Option) ? (prefixes as Option).value : '',
        ...(!!externalUsers.length && { externalUsers }),
        ...(!!groups.length && { groups: (groups as string).split(',') }),
        ...(!!users.length && { users: (users as string).split(',') }),
      });
    }
  }

  return (
    <form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.stopPropagation();

        return rhfSubmit(handleSubmit)(e);
      }}
    >
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Card sx={{ flexBasis: 0, flexGrow: 1, flexShrink: 0 }}>
            <CardHeader
              sx={{ bgcolor: 'filler.main', p: 1 }}
              title={t('add_user_title', 'User Selection')}
              titleTypographyProps={{ fontWeight: 700, variant: 'body1' }}
            />

            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Autocomplete
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('filter_group_field', 'Filter by Group')}
                name="filterGroup"
                options={groups}
                PopperComponent={(props) => <StyledPopper {...props} />}
                shouldVirtualize
              />

              <Autocomplete
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('user_selection_field', 'User Selection')}
                multiple
                name="users"
                options={
                  filterGroup
                    ? users.filter(({ value: userId }) =>
                        rawGroups[parseInt(filterGroup.value)].users.includes(parseInt(userId)),
                      )
                    : users
                }
                PopperComponent={(props) => <StyledPopper {...props} />}
                rules={{
                  validate: {
                    ...isRequired,
                  },
                }}
                shouldVirtualize
              />
            </CardContent>
          </Card>

          <Card sx={{ flexBasis: 0, flexGrow: 1, flexShrink: 0 }}>
            <CardHeader
              sx={{ bgcolor: 'filler.main', p: 1 }}
              title={t('add_group_title', 'Group Selection')}
              titleTypographyProps={{ fontWeight: 700, variant: 'body1' }}
            ></CardHeader>

            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Autocomplete
                control={control}
                disabled={isSubmitting}
                isFetching={isFetching}
                label={t('group_selection_field', 'Group Selection')}
                multiple
                name="groups"
                options={groups}
                PopperComponent={(props) => <StyledPopper {...props} />}
                rules={{
                  validate: {
                    ...isRequired,
                  },
                }}
                shouldVirtualize
              />
            </CardContent>
          </Card>
        </Box>

        <Autocomplete
          control={control}
          disabled={isSubmitting}
          freeSolo
          getChipColor={(value) =>
            isEmailAddressValid((value as Option).value) ? 'primary' : 'error'
          }
          isFetching={isFetching}
          label={t('external_users_field', 'External Users')}
          multiple
          name="externalUsers"
          options={[]}
          PopperComponent={(props) => <StyledPopper {...props} />}
          rules={{
            validate: {
              ...isRequired,
              isEmailAddressValid: (value) =>
                (value as string[]).every((value) => isEmailAddressValid(value)) ||
                (t(
                  'Alle Werte müssen gültige E-Mail-Adressen sein.',
                  'All values must be valid email addresses.',
                ) as string),
            },
          }}
        />

        <Box sx={{ display: 'flex', gap: 2 }}>
          {shouldRenderPositionAndDay ? (
            <Autocomplete
              control={control}
              disabled={isSubmitting}
              isFetching={isFetching}
              label={t('lib.pos', 'Position')}
              name="position"
              options={positions}
              PopperComponent={(props) => <StyledPopper {...props} />}
              rules={REQUIRED_RULE}
            />
          ) : null}

          {shouldRenderPositionAndDay ? (
            <Autocomplete
              control={control}
              disabled={isSubmitting}
              isFetching={isFetching}
              label={t('lib.days', 'Days')}
              name="duration"
              options={duration}
              PopperComponent={(props) => <StyledPopper {...props} />}
              rules={REQUIRED_RULE}
            />
          ) : null}

          {!isNewApproval && prefixes.length ? (
            <Autocomplete
              control={control}
              disabled={isSubmitting}
              isFetching={isFetching}
              label={t('subloop', 'Subloop')}
              name="prefixes"
              options={prefixes}
              PopperComponent={(props) => <StyledPopper {...props} />}
              rules={REQUIRED_RULE}
            />
          ) : null}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button color="warning" disabled={!isDirty} onClick={handleClickReset}>
            {t('jfl.reset', 'Reset')}
          </Button>

          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            {t('lib.confirm', 'Confirm')}
          </LoadingButton>
        </Box>
      </AccordionDetails>
    </form>
  );
}
