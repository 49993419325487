import { ChangeEvent, useEffect, useState } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import {
  Box,
  CircularProgress,
  IconButton,
  IconProps,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { ColorDialog } from 'src/features/JobForm/components/ColorDialog';
import { isMemo } from 'src/features/JobForm/components/FieldMapper2/utilities/helperFunctions';
import { TranslationDialog } from 'src/features/JobForm/components/TranslationDialog';
import { WaveIcon } from 'src/features/WaveIcon';
import { useDebounce, usePreference } from 'src/utilities/hooks';

// Needed to provide correct height for empty fields.
const EMPTY_STRING_FALLBACK = '\u00A0';

type TextFieldOwnProps = {
  description?: string;
  fullHeight?: boolean;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  isFetching?: boolean;
  isError?: boolean;
  isReadOnly?: boolean;
  isRawText?: boolean;
  shouldShowHelperText?: boolean;
  fieldValueIndex?: number;
  isLabelledText?: boolean;
};
type TextFieldProps = Omit<MuiTextFieldProps, 'defaultValue' | 'error' | 'required'> &
  TextFieldOwnProps;

export function TextField<T extends FieldValues>({
  control,
  description,
  disabled,
  fieldValueIndex,
  fullHeight = false,
  isError,
  isFetching = false,
  isLabelledText = false,
  isRawText = false,
  isReadOnly = false,
  label,
  name,
  onBlur,
  onChange,
  onChangeLogicBuilderField,
  rules,
  shouldShowHelperText = true,
  size = 'small',
  sx,
  type = 'search',
  variant = 'outlined',
  ...rest
}: TextFieldProps & Omit<UseControllerProps<T>, 'defaultValue'>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isColorDialogOpen, setIsColorDialogOpen] = useState(false);
  const showTooltipsPreference = usePreference('job.feldtips', 'Y');

  const {
    field: { onBlur: onFieldBlur, onChange: onFieldChange, ref, value, ...field },
    fieldState: { error },
  } = useController({
    control,
    name,
    rules: {
      ...rules,
      validate: rules?.validate,
    },
  });

  const fieldValue = fieldValueIndex !== undefined ? value?.[fieldValueIndex] || '' : value;

  const [waveIconCode, waveIconColor] = !fieldValue?.length
    ? ['input-field-empty', 'primary']
    : !!error || isError
    ? ['input-field-invalid', 'error']
    : ['input-field-valid', 'success'];

  const debouncedFieldValue = useDebounce(fieldValue, 800);

  function handleEventChange(event: ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;

    handleChange(newValue, event);
  }

  function handleChange(newValue: string, event?: ChangeEvent<HTMLInputElement>) {
    if (fieldValueIndex !== undefined && Array.isArray(value)) {
      const updatedValues = [...value];

      updatedValues[fieldValueIndex] = newValue;
      onFieldChange(updatedValues);
    } else {
      onFieldChange(newValue);
    }

    if (onChange && event) onChange(event);
  }

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleOpenColorDialog() {
    setIsColorDialogOpen(true);
  }

  useEffect(() => {
    if (debouncedFieldValue && onChangeLogicBuilderField) {
      onChangeLogicBuilderField(name);
    }
  }, [debouncedFieldValue, onChangeLogicBuilderField, name]);

  if (isRawText) return <Typography>{type === 'number' && !value ? '0' : value}</Typography>;

  if (isReadOnly) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>
          {type === 'number' && !value ? '0' : value || EMPTY_STRING_FALLBACK}
        </Typography>
      </Box>
    );
  }

  if (isLabelledText) {
    return (
      <Box>
        <Typography color="text.secondary" variant="caption">
          {label}
        </Typography>

        <Typography>
          {type === 'number' && !value ? '0' : value || EMPTY_STRING_FALLBACK}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <MuiTextField
        disabled={disabled || isFetching}
        error={!!error || isError}
        fullWidth
        inputRef={ref}
        label={label}
        multiline={isMemo(type)}
        onBlur={(event) => {
          onFieldBlur();

          if (onBlur) {
            onBlur(event);
          }
        }}
        onChange={handleEventChange}
        required={Boolean(rules?.required)}
        rows={isMemo(type) ? 5 : 0}
        size={size}
        style={{ height: fullHeight ? '100%' : undefined }}
        sx={{
          ...sx,
          '& .MuiInputBase-input': {
            height: fullHeight ? '100% !important' : undefined,
            overflow: fullHeight ? 'auto !important' : undefined,
          },
          '& .MuiInputBase-root': {
            alignItems: fullHeight ? 'start' : undefined,
            display: fullHeight ? 'flex' : undefined,
            height: fullHeight ? '100%' : undefined,
          },
        }}
        type={type}
        value={fieldValue || ''}
        variant={variant === 'outlined' ? 'outlined' : label ? variant : 'filled'}
        {...(type === 'barcode' && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <WaveIcon
                  code={waveIconCode}
                  color={waveIconColor as IconProps['color']}
                  fontSize="small"
                />
              </InputAdornment>
            ),
          },
        })}
        {...(type === 'core_color' && {
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  disabled={disabled || isFetching}
                  edge="end"
                  onClick={handleOpenColorDialog}
                >
                  <WaveIcon code="color-dropdown" fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          },
        })}
        {...(isFetching && {
          InputProps: {
            endAdornment: (
              <Box display="flex">
                <CircularProgress size={24} />
              </Box>
            ),
          },
        })}
        {...(!label && { placeholder: name })}
        {...field}
        {...(shouldShowHelperText && { helperText: (error?.message && error.message) ?? ' ' })}
        {...rest}
      />

      {showTooltipsPreference.value === 'Y' && description ? (
        <WaveTooltip
          body={description}
          component={<WaveIcon code="input-field-information" />}
          placement="top"
          type="simple"
        />
      ) : null}

      {isMemo(type) && import.meta.env.VITE_INSTALLATION === 'hubx' ? (
        <IconButton onClick={handleOpenDialog}>
          <WaveIcon code="job-form-language" color="primary" />
        </IconButton>
      ) : null}

      {isOpen && <TranslationDialog isOpen={isOpen} onSetIsOpen={setIsOpen} value={fieldValue} />}

      {isColorDialogOpen ? (
        <ColorDialog
          fieldValueIndex={fieldValueIndex}
          isColorDialogOpen={isColorDialogOpen}
          onHandleChange={handleChange}
          onSetIsColorDialogOpen={setIsColorDialogOpen}
        />
      ) : null}
    </>
  );
}
