import { Fragment } from 'react';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { RichText } from 'src/components/RHF/RichText';
import { TextField } from 'src/components/RHF/TextField';
import { ICON_COLORS } from 'src/features/Approvals/components/DetailsTable/DetailsTable';
import { WaveIcon } from 'src/features/WaveIcon';

type StandardLayoutProps = {
  accordionSections?: Record<string, boolean>;
  alias: string;
  control: Control<any>;
  getValues: UseFormGetValues<FieldValues>;
  isDirty: boolean;
  layout: string;
  masterContent?: string;
  resetField: UseFormResetField<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

export function StandardLayout({
  alias,
  control,
  getValues,
  isDirty,
  layout,
  masterContent,
  resetField,
  setValue,
}: StandardLayoutProps) {
  const { t } = useTranslation('');
  const reviewAction = getValues(`${alias}.type`);
  const isRTFLayout = layout === 'RTF';

  function handleSetReview(type: string) {
    setValue(`${alias}.type`, type);
    if (type === 'approval') {
      setValue(`${alias}.masterContent`, masterContent);
    }
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              sx={{
                backgroundColor: `${
                  reviewAction === 'approval' ? ICON_COLORS[reviewAction] : 'primary.main'
                }`,
                color: 'white',
                px: 1,
                py: 1,
              }}
              title={
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Typography component="p">
                    <Trans i18nKey="apl-phtra.phrase.content.titel">Master Content</Trans>
                  </Typography>

                  <IconButton
                    onClick={() => navigator.clipboard.writeText(masterContent ?? '')}
                    size="small"
                    sx={{ color: 'white', height: 24 }}
                  >
                    <WaveIcon code="copy" fontSize="small" />
                  </IconButton>
                </Box>
              }
            />

            <CardContent
              sx={{ height: '100%', padding: isRTFLayout ? 0 : 2, paddingBottom: '0 !important' }}
            >
              {isRTFLayout ? (
                <RichText
                  control={control}
                  disabled={true}
                  name={`${alias}.masterContentComment`}
                  placeholder={t('master_content_comment', 'Master Content Comment') as string}
                  toolbar={[
                    ['bold', 'italic', 'underline'],
                    [{ script: 'sub' }, { script: 'super' }],
                  ]}
                />
              ) : (
                <TextField
                  control={control}
                  disabled={true}
                  name={`${alias}`}
                  type="memo"
                  variant="filled"
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader
              sx={{
                backgroundColor: `${
                  reviewAction === 'conditional' ? ICON_COLORS[reviewAction] : 'primary.main'
                }`,
                color: 'white',
                padding: 1,
              }}
              title={
                <Typography component="p">
                  <Trans i18nKey="lib.suggestion">Suggestion</Trans>
                </Typography>
              }
            />

            <CardContent
              sx={{ height: '100%', padding: isRTFLayout ? 0 : 2, paddingBottom: '0 !important' }}
            >
              {isRTFLayout ? (
                <RichText
                  control={control}
                  name={`${alias}.suggestion`}
                  placeholder={t('add_suggestion', 'Add Suggestion') as string}
                  toolbar={[
                    ['bold', 'italic', 'underline'],
                    [{ script: 'sub' }, { script: 'super' }],
                  ]}
                />
              ) : (
                <TextField
                  control={control}
                  label={t('add_suggestion', 'Add Suggestion')}
                  name={`${alias}.suggestion`}
                  type="memo"
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        {isRTFLayout ? (
          <>
            <Grid xs={6}>
              <Typography variant="caption">{`${masterContent?.length}/150`}</Typography>
            </Grid>

            <Grid xs={6}>
              <Typography variant="caption">{'0/150'}</Typography>
            </Grid>
          </>
        ) : null}

        {!reviewAction ? (
          <Grid mt={1} xs>
            <TextField
              control={control}
              label={t('lib.msg', 'Comment')}
              name={`${alias}.comment`}
              type="memo"
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid container justifyContent="flex-end" spacing={1}>
        {!reviewAction ? (
          <>
            <Grid>
              <Button
                color="warning"
                disabled={!isDirty}
                onClick={() => handleSetReview('conditional')}
                startIcon={<WaveIcon code="job-approvals-revisor-status-conditional" />}
                type="submit"
                value="conditional"
              >
                <Trans i18nKey="make_suggestion">Make Suggestion</Trans>
              </Button>
            </Grid>

            <Grid>
              <Button
                color="success"
                onClick={() => handleSetReview('approval')}
                startIcon={<WaveIcon code="job-approvals-revisor-status-approval" />}
                type="submit"
                value="approval"
              >
                <Trans i18nKey="accept_supplier_version">Accept Supplier Version</Trans>
              </Button>
            </Grid>
          </>
        ) : (
          <Grid>
            <Button
              color="primary"
              onClick={() => resetField(`${alias}`)}
              startIcon={<WaveIcon code="edit" />}
              type="submit"
            >
              <Trans i18nKey="change_review">Change Review</Trans>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
}
