import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { t } from 'i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconProps,
  Skeleton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import {
  SubApprovalDetail,
  useGetContentApprovalQuery,
} from 'src/features/Approvals/Approvals.service';
import { NutritionLayout } from 'src/features/Approvals/components/MasterContent/components/NutritionLayout';
import { StandardLayout } from 'src/features/Approvals/components/MasterContent/components/StandardLayout';
import { useGetCategoriesQuery } from 'src/features/Content/content.service';
import { setContentListInitialValues } from 'src/features/Content/content.utils';
import { useRouteParams } from 'src/utilities/hooks';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { WaveIcon } from '../../../WaveIcon';

import { JobContentItem } from 'src/features/Content/Content.types';

type MasterContentProps = {
  isMasterContentOpen: boolean;
  onClose: () => void;
  selectedSubApprovalDetails: SubApprovalDetail;
};

type FormData = {
  [x: string]: Record<string, string>;
};

export function MasterContent({
  isMasterContentOpen,
  onClose,
  selectedSubApprovalDetails,
}: MasterContentProps) {
  const { age, jobId, jobType } = useRouteParams();

  const ICON_COLORS: Record<string, IconProps['color']> = {
    amendment: 'error',
    approval: 'success',
    conditional: 'warning',
    unactioned: 'primary',
  };

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    resetField,
    setValue,
  } = useForm({ mode: 'onChange' });

  const { data: contents } = useGetContentApprovalQuery<{
    data: JobContentItem[];
    isFetching: boolean;
  }>({
    age,
    jobId,
    loopId: selectedSubApprovalDetails.loop_id,
    prefix: selectedSubApprovalDetails.prefix,
    src: jobType,
    task: selectedSubApprovalDetails.task,
  });

  const { data: categoriesList = [], isFetching: areCategoriesFetching } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );
  const contentList = setContentListInitialValues(contents, 'category_id');

  const isLoading = areCategoriesFetching || !contentList?.length;

  const [shouldHideReviewedContent, setShouldHideReviewedContent] = useState(true);
  const [accordionSections, setAccordionSections] = useState<Record<string, boolean>>({});
  const [shouldCollapseExpandAll, setShouldCollapseExpandAll] = useState(false);
  const [hideReviewContent, setHideReviewContent] = useState<string[]>();

  async function onSubmit(data: FormData) {
    let collapseContent: Record<number, boolean> = {};
    const reviewedContent = Object.keys(data).filter((key) => data[key]?.type);

    const expandNextReviewedContent =
      contentList[
        contentList.findIndex((el) => el.content === reviewedContent[reviewedContent.length - 1]) +
          1
      ]?.content;

    reviewedContent.map((content) => {
      return (collapseContent = { ...collapseContent, [content]: false });
    });

    setAccordionSections({
      ...accordionSections,
      ...collapseContent,
      [expandNextReviewedContent]: true,
    });
    setHideReviewContent(reviewedContent);
  }

  function handleToggleCollapseExpandAll() {
    setShouldCollapseExpandAll(!shouldCollapseExpandAll);
    contentList.map(({ content }) => {
      setAccordionSections((prev) => ({
        ...prev,
        [`${content}`]: !shouldCollapseExpandAll,
      }));
    });
  }

  function handleAccordionChange(alias: string) {
    setAccordionSections({
      ...accordionSections,
      [alias]: !accordionSections?.[alias],
    });
  }

  function renderCategories() {
    return contentList?.map(({ category_id: categoryId, content, index, layout, totalCount }) => {
      const category = categoriesList.find((category) => category.id === Number(categoryId));
      const reviewedAction = getValues(`${content}.type`);

      return (
        <Accordion
          expanded={accordionSections?.[content] ?? false}
          key={content}
          onChange={() => handleAccordionChange(content)}
          sx={{
            display: `${
              hideReviewContent?.includes(content) && shouldHideReviewedContent ? 'none' : 'block'
            }`,
          }}
        >
          <AccordionSummary
            sx={{ backgroundColor: 'filler.main', color: 'primary.main', padding: 1 }}
          >
            <WaveIcon
              code={reviewedAction || 'edit'}
              color={ICON_COLORS[reviewedAction] || ('primary' as IconProps['color'])}
              fontSize="small"
            />

            <Typography fontWeight={500} paddingLeft={1}>
              {(totalCount as number) > 1 ? `${category?.value} ${index}` : category?.value}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {layout === 'memo' || layout === 'RTF' ? (
              <StandardLayout
                accordionSections={accordionSections}
                alias={content}
                control={control}
                getValues={getValues}
                isDirty={isDirty}
                layout={layout}
                masterContent={content}
                resetField={resetField}
                setValue={setValue}
              />
            ) : layout === 'nutrition' ? (
              <NutritionLayout alias={content} control={control} setValue={setValue} />
            ) : (
              <TextField
                control={control}
                label={t('lib.msg', 'Comment')}
                name={`${content}.comment`}
                type="memo"
              />
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  useEffect(() => {
    if (contentList?.length && !Object.keys(accordionSections).length) {
      const initialAccordionState: Record<number, boolean> = contentList.reduce(
        (acc, { content }, index) => {
          return {
            ...acc,
            [content]: index === 0,
          };
        },
        {},
      );

      setAccordionSections(initialAccordionState);
    }
  }, [contentList]);

  useEffect(() => {
    setShouldCollapseExpandAll(!Object.values(accordionSections ?? '').includes(false));
  }, [accordionSections]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        onClose();
        reset();
      }}
      open={isMasterContentOpen}
    >
      <WaveDialogTitle
        onClose={onClose}
        title={t('apl.phrase.content.title', 'Review Master Content')}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldHideReviewedContent}
                    disabled={isLoading}
                    onClick={() => setShouldHideReviewedContent(!shouldHideReviewedContent)}
                  />
                }
                label={t('hide_reviewed_content', 'Hide Reviewed Content')}
              />
            </Grid>

            <Grid>
              <Button
                disabled={isLoading}
                onClick={handleToggleCollapseExpandAll}
                startIcon={
                  <WaveIcon code={shouldCollapseExpandAll ? 'expand-less' : 'expand-more'} />
                }
              >
                {shouldCollapseExpandAll ? 'Collapse All' : 'Expand All'}
              </Button>
            </Grid>
          </Grid>

          {isLoading
            ? [...Array(3)].map((_, index) => (
                <Box key={index} py={0.5}>
                  <Skeleton height={30} variant="rounded" />
                </Box>
              ))
            : renderCategories()}
        </DialogContent>

        <DialogActions className="space-between">
          <Button color="warning" onClick={onClose}>
            <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
          </Button>

          <Button type="submit">Complete Review</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
