import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { Option } from 'src/components/RHF/Dropdown/Dropdown';
import { CategorizedFiles, RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

import { JobContentItem } from 'src/features/Content/Content.types';

type BaseParams = {
  age?: ExpandedAges;
};
type AddRevisorRequest = {
  duration?: string | number;
  externalUsers?: string[];
  groups?: string[];
  jobId?: number | string;
  jobType?: string;
  method?: string;
  position?: string | number;
  prefixes: string;
  users?: string[];
} & BaseParams;
type CompareFilesRequest = {
  file_id?: number[];
  jobid?: number | string;
  src?: string;
  sub?: string;
} & BaseParams;
type DeleteRevisorRequest = {
  gid: number | null;
  jobid?: number | string;
  loop_id: number;
  loop_user_id: number | null;
  src?: string;
  states_id: number;
} & BaseParams;
type GetActionsRequest = {
  jobid?: string | number;
  src?: string;
} & BaseParams;
type GetAddRevisorFormRequest = {
  loopId?: number;
  templateId?: number;
  src?: string;
  jobid?: string | number;
} & BaseParams;
type GetApprovalsRequest = {
  src?: string;
  jobid?: string | number;
} & BaseParams;
type GetFilesRequest = {
  jobId?: number | string;
  src?: string;
  type?: string;
} & BaseParams;
type GetActiveApprovalsRequest = {
  loop_id: number;
} & BaseParams;
type GetContentApprovalRequest = {
  jobId?: number | string;
  src?: string;
  loopId: number;
  prefix: string;
  task: string;
} & BaseParams;

type Actions = {
  active_user_actions: Array<{
    code: string;
    text: string;
    flag: string;
  }>;
  file_actions: Array<{
    code: string;
    text: string;
  }>;
  main_actions: Array<{
    code: string;
    prefix: string;
    text: string;
  }>;
  email_actions: Array<{
    code: string;
    text: string;
  }>;
};
export type Approval = {
  approval: SubApproval | Array<SubApproval>;
  closed: string | null;
  creation: string;
  deadline: string | null;
  id: number;
  jobid: number;
  num: number;
  status: 'open' | 'closed';
  thumbnail: string;
  title: string;
  type: string;
};
type AddRevisorForm = {
  days: Record<number | string, string>;
  groups: Record<number, GroupsDetails>;
  positions: Record<number | string, string>;
  prefixes: Record<string, string>;
  users: ApprovalUsers;
};
type AddRevisorFormTransformed = {
  duration: Option[];
  groups: Option[];
  positions: Option[];
  prefixes: Option[];
  rawGroups: Record<number, GroupsDetails>;
  rawUsers: ApprovalUsers;
  users: Option[];
};

export type FileDocType = {
  id: string;
  displayName: string;
  revisions?: {
    id: string;
    displayName: string;
  }[];
};

type OtherDetailsType = {
  base_url?: string;
  docs?: FileDocType[];
  js_path?: string;
  username?: string;
  language?: string;
  token?: string;
  session?: string;
  url?: string;
  can_annotate?: boolean;
}

type CompareFilesResponse = {
  other_details: OtherDetailsType;
  url: string;
  view_type: string;
}

export type ApprovalUsers = Record<number, string>;
type GroupsDetails = {
  admin_level: string;
  chk_master_src: string;
  cnd: number;
  cnd_sql: string;
  code: string;
  comp_dom: string | null;
  comp_name: string | null;
  del: string;
  gru_email: string | null;
  id: number;
  kundenId: string;
  ldap_name: string;
  mand: number;
  max_usr: string | null;
  name: string;
  parent_id: number;
  struc_parent: string | null;
  tableau_group: string;
  typ: string | null;
  users: Array<number>;
};
export type SubApproval = {
  id: null | number;
  close_date: string;
  create_date: string;
  details: Array<SubApprovalDetail>;
  prefix: string;
  state: string;
};
export type SubApprovalDetail = {
  annotations: Array<{ comment: string; date: string; title: string; page: number }>;
  approver_type: 'group' | 'user';
  can_delete: boolean;
  comment: string | null;
  date: string;
  days: number;
  files: string | null;
  group: string;
  gru_id: number;
  id: number;
  loop_id: number;
  position: number;
  prefix: string;
  status: string;
  task: string;
  typ: string;
  user: string;
  user_id: number;
  users?: Array<{ user_id: number; name: string }>;
};

export const approvalsApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    addRevisor: build.mutation<string, AddRevisorRequest>({
      invalidatesTags: ['ActionBar', 'Approvals', 'SubApprovalsForExpandedApproval'],
      query({
        age,
        duration,
        externalUsers,
        groups,
        jobId,
        jobType,
        method,
        position,
        prefixes,
        users,
      }) {
        return {
          method: 'POST',
          params: {
            age,
            duration,

            jobid: jobId,
            method,
            position,
            prefix: prefixes,
            src: jobType,
            ...(externalUsers && { external_users: externalUsers }),
            ...(groups && { groups }),
            ...(users && { users }),
          },
          url: '/approvers/add',
        };
      },
    }),
    compareFiles: build.query<CompareFilesResponse, CompareFilesRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/jobs/files/compare',
        };
      },
    }),
    deleteRevisor: build.mutation<string, DeleteRevisorRequest>({
      invalidatesTags: ['ActionBar', 'Approvals', 'SubApprovalsForExpandedApproval'],
      query(params) {
        return {
          method: 'DELETE',
          params,
          url: '/approvers/delete',
        };
      },
    }),
    getActions: build.query<Actions, GetActionsRequest>({
      providesTags: ['ActionBar'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/approvalloop/getbuttons',
        };
      },
    }),
    getAddRevisorForm: build.query<AddRevisorFormTransformed, GetAddRevisorFormRequest>({
      query({ age, jobid, loopId, src, templateId }) {
        return {
          method: 'GET',
          params: {
            age,
            jobid,
            loop_id: loopId,
            src,
            template_id: templateId,
          },
          url: '/approvers/getdataforaddrevisordialog',
        };
      },
      transformResponse({
        days,
        groups,
        positions,
        prefixes,
        users,
      }: AddRevisorForm): AddRevisorFormTransformed {
        const duration = Object.entries(days)
          .filter(([value]) => value !== '')
          .map(([value, label]) => {
            return {
              label,
              value,
            };
          });
        const _groups = sortArrayOfObjectsByKey(
          Object.entries(groups)
            .filter(([groupId]) => groupId !== '0')
            .map(([groupId, { name }]) => {
              return {
                label: name,
                value: groupId,
              };
            }),
          'label',
        );
        const _positions = Object.entries(positions)
          .filter(([value]) => value !== '')
          .map(([value, label]) => {
            return {
              label,
              value,
            };
          });
        const _prefixes = Object.entries(prefixes)
          .filter(([value]) => value !== '')
          .map(([value, label]) => {
            return {
              label,
              value,
            };
          });
        const _users = sortArrayOfObjectsByKey(
          Object.entries(users).map(([userId, username]) => {
            return {
              label: username,
              value: userId,
            };
          }),
          'label',
        );

        return {
          duration,
          groups: _groups,
          positions: _positions,
          prefixes: _prefixes,
          rawGroups: groups,
          rawUsers: users,
          users: _users,
        };
      },
    }),
    getApprovals: build.query<Array<Approval>, GetApprovalsRequest>({
      providesTags: ['Approvals'],
      query(params) {
        return {
          method: 'GET',
          params: {
            ...params,
            load_states_for_active_apl: 1,
          },
          url: '/approvalloop/getjobapproval',
        };
      },
    }),
    getContentApproval: build.query<Array<JobContentItem>, GetContentApprovalRequest>({
      providesTags: ['SubApprovalsForExpandedApproval'],
      query({ age, jobId, loopId, prefix, src, task }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, loop_id: loopId, prefix, src, task },
          url: `/jobs/${jobId}/content-approvals`,
        };
      },
    }),
    getFiles: build.query<
      { categorizedFiles: CategorizedFiles; rawFiles: RawFiles },
      GetFilesRequest
    >({
      query({ age, jobId, src, type = 'cloudflow' }) {
        return {
          method: 'GET',
          params: {
            age,
            jobid: jobId,
            src,
            sub: type,
          },
          url: '/jobs/files/get',
        };
      },
      transformResponse(response: RawFiles): {
        categorizedFiles: CategorizedFiles;
        rawFiles: RawFiles;
      } {
        const categorizedResponse: CategorizedFiles = {};

        response
          .sort((a, b) => b.version - a.version)
          .forEach((file) => {
            if (categorizedResponse[file.category]) {
              categorizedResponse[file.category].push(file);
            } else {
              categorizedResponse[file.category] = [file];
            }
          });

        return {
          categorizedFiles: categorizedResponse,
          rawFiles: response,
        };
      },
    }),
    getSubApprovalsForExpandedApproval: build.query<Array<SubApproval>, GetActiveApprovalsRequest>({
      providesTags: ['SubApprovalsForExpandedApproval'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/approvalloopstates/active',
        };
      },
    }),
    setApproverStatus: build.mutation<{ message: string }, FormData>({
      invalidatesTags: ['ActionBar', 'Approvals', 'SubApprovalsForExpandedApproval'],
      query(formData) {
        return {
          data: formData,
          method: 'POST',
          url: '/approvers/update',
        };
      },
    }),
  }),
  reducerPath: 'approvalsApi',
  tagTypes: ['ActionBar', 'Approvals', 'SubApprovalsForExpandedApproval'],
});

export const {
  useAddRevisorMutation,
  useCompareFilesQuery,
  useDeleteRevisorMutation,
  useGetActionsQuery,
  useGetAddRevisorFormQuery,
  useGetApprovalsQuery,
  useGetContentApprovalQuery,
  useGetFilesQuery,
  useGetSubApprovalsForExpandedApprovalQuery,
  useSetApproverStatusMutation,
} = approvalsApi;
