import { IconButton, Snackbar, SnackbarContent, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/utilities/hooks';
import { WaveIcon } from '../../features/WaveIcon';
import { closeWaveSnack } from '../../store/waveSnackSlice';

type Message = {
  message: string | string[];
  type: 'success' | 'error';
};

function formatMessage(message: string | string[]) {
  return Array.isArray(message)
    ? message.map((messageLine) => (
        <Typography component="p" key={messageLine} variant="body2">
          {messageLine}
        </Typography>
      ))
    : message;
}

export function WaveSnack() {
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.waveSnack.messages);

  function handleOnClick(index: number) {
    dispatch(closeWaveSnack(index));
  }

  return (
    <>
      {messages.map(({ message, type }: Message, index) => (
        <Snackbar
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          autoHideDuration={type === 'success' ? 3000 : null}
          key={index}
          open
          style={{ top: `${index * 50 + 20}px` }}
        >
          <SnackbarContent
            action={
              type !== 'success' && (
                <IconButton color="inherit" onClick={() => handleOnClick(index)} size="small">
                  <WaveIcon code="close" fontSize="small" />
                </IconButton>
              )
            }
            classes={{ message: 'm-auto', root: type }}
            message={formatMessage(message)}
          />
        </Snackbar>
      ))}
    </>
  );
}
