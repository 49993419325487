import { ChangeEvent, Fragment, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import { Thumbnail } from 'src/components/Thumbnail';
import { JobFile, RawFiles } from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { formatBytes } from 'src/utilities/helperFunctions';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { useDateFormat, useExpand, useViewer } from 'src/utilities/hooks';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';

type FilesTableProps = {
  files: RawFiles;
  onDeleteClick?: (file: JobFile) => void;
  onSelectAllFilesToggle: (itemIds: number[], selected: boolean) => void;
  onSelectFileClick: (fileID: number, checked: boolean) => void;
  selectedFileIds: number[];
};

type Order = 'asc' | 'desc';

const COLUMNS = [
  { code: 'filename', colSpan: 2, text: 'lib.file.name' },
  { code: 'filesize', colSpan: 0, text: 'lib.file.size' },
  { code: 'date', colSpan: 0, text: 'files_upload_date_header' },
  { code: 'user', colSpan: 0, text: 'files_uploaded_by_header' },
];

export function FilesTable({
  files,
  onDeleteClick,
  onSelectAllFilesToggle,
  onSelectFileClick,
  selectedFileIds,
}: FilesTableProps) {
  const { expandedItems, toggleExpandItem } = useExpand(
    parseArrayToLogicalObject(
      files.filter(({ comment }) => comment),
      'id',
    ),
  );
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('filename');
  const { openViewerPage } = useViewer();

  const fileIds = files.map(({ id }) => id);
  const hasCommentsInTable = files.some(({ comment }) => comment);
  const isIndeterminate = selectedFileIds.length > 0 && selectedFileIds.length < files.length;
  const { t } = useTranslation();

  const { formatDate } = useDateFormat();

  function handleSelectAllCheck(e: ChangeEvent<HTMLInputElement>) {
    onSelectAllFilesToggle(fileIds, e.target.checked);
  }

  function handleSort(column: string) {
    const isAsc = orderBy === column && order === 'asc';

    setOrderBy(column);
    setOrder(isAsc ? 'desc' : 'asc');
  }

  function handleClickThumbnailContainer(e: MouseEvent<HTMLElement>, fileId: number, type: string) {
    e.stopPropagation();

    openViewerPage({ fileId, type });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Box display="flex" {...(hasCommentsInTable && { ml: 5 })}>
                <Checkbox
                  checked={files.length > 0 && selectedFileIds.length === files.length}
                  color={isIndeterminate ? 'secondary' : 'primary'}
                  indeterminate={isIndeterminate}
                  onChange={handleSelectAllCheck}
                  size="small"
                />
              </Box>
            </TableCell>

            {COLUMNS.map(({ code, colSpan, text }) => (
              <Box
                colSpan={colSpan}
                component={TableCell}
                fontWeight="bold"
                key={code}
                sortDirection={order}
              >
                <TableSortLabel
                  active={code === orderBy}
                  direction={order}
                  onClick={() => handleSort(code)}
                >
                  {t(text)}
                </TableSortLabel>
              </Box>
            ))}

            {onDeleteClick ? <TableCell /> : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {(order === 'asc'
            ? sortArrayOfObjectsByKey(files, orderBy)
            : sortArrayOfObjectsByKey(files, orderBy).reverse()
          ).map((file: JobFile) => {
            return (
              <Fragment key={file.id}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Box display="flex" {...(hasCommentsInTable && !file.comment && { ml: 5 })}>
                      {file.comment ? (
                        <IconButton onClick={() => toggleExpandItem(file.id)}>
                          <WaveIcon code={expandedItems[file.id] ? 'expand-less' : 'expand-more'} />
                        </IconButton>
                      ) : null}

                      <Checkbox
                        checked={selectedFileIds.includes(file.id)}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          onSelectFileClick(file.id, event.target.checked)
                        }
                        size="small"
                      />
                    </Box>
                  </TableCell>

                  <TableCell width="35%">
                    <Box alignItems="center" display="flex">
                      <Box
                        flexShrink={0}
                        onClick={(e) => handleClickThumbnailContainer(e, file.id, file.type)}
                        sx={{ cursor: 'pointer', mx: 1 }}
                      >
                        <Thumbnail
                          alt={`general file thumbnail ${file.filename}`}
                          size={50}
                          type={file.type}
                          url={file.thumbnail_url}
                        />
                      </Box>

                      <Typography variant="inherit">{`${t('version', 'Version')} ${
                        file.version
                      } - ${file.filename}`}</Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    <Chip
                      color={file.src !== 'pro' ? 'primary' : undefined}
                      label={
                        file.src !== 'pro'
                          ? t('job_file', 'Job File')
                          : t('project_file', 'Project File')
                      }
                      size="small"
                    />
                  </TableCell>

                  <TableCell>{formatBytes(file.filesize)}</TableCell>

                  <TableCell>{formatDate(LONG_DATE_FORMAT, file.date)}</TableCell>

                  <TableCell width="20%">{file.user}</TableCell>

                  {file.can_delete && onDeleteClick ? (
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        onClick={(e: MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          onDeleteClick(file);
                        }}
                        size="small"
                      >
                        <WaveIcon code="delete" fontSize="small" />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                </TableRow>

                {file.comment ? (
                  <TableRow>
                    <Box
                      {...(!expandedItems[file.id] && { border: 0 })}
                      colSpan={6}
                      component={TableCell}
                      pb={0}
                      pt={0}
                    >
                      <Collapse in={expandedItems[file.id]} timeout="auto" unmountOnExit>
                        <Box bgcolor="var(--filler)" borderRadius={1} my={1} p={1}>
                          <Typography display="block" fontWeight={600} variant="caption">
                            {t('lib.msg', 'Comment')}:
                          </Typography>

                          <Typography variant="caption">{file.comment}</Typography>
                        </Box>
                      </Collapse>
                    </Box>
                  </TableRow>
                ) : null}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
