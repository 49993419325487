import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import { Thumbnail } from 'src/components/Thumbnail';
import { DeleteButton } from 'src/features/FileExplorer/components/DeleteButton';
import { DownloadButton } from 'src/features/FileExplorer/components/DownloadButton';
import { SelectedFiles } from 'src/features/FileExplorer/components/Files/Files';
import { FilesType, GeneralFile } from 'src/features/FileExplorer/FileExplorer.service';
import { CellSkeleton } from 'src/features/JobsTable/components/CellSkeleton';
import { formatBytes } from 'src/utilities/helperFunctions';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';
import { useDateFormat } from 'src/utilities/hooks';

type FilesTableProps = {
  areFilesFetching: boolean;
  files: FilesType;
  onDeleteCallback: () => void;
  onHandleClickRowCheckbox: (e: ChangeEvent<HTMLInputElement>, fileID: number) => void;
  onToggleSelectAll: () => void;
  onToggleSelectFile: (fileId: number) => void;
  selectedFileIds: number[];
  selectedFiles: SelectedFiles;
  selectedFolder: string;
};
type Order = 'asc' | 'desc';

const columns = [
  { code: 'file_name', text: 'lib.file.name' },
  { code: 'size', text: 'lib.file.size' },
  { code: 'date', text: 'files_upload_date_header' },
  { code: 'user', text: 'files_uploaded_by_header' },
];

export function FilesTable({
  areFilesFetching,
  files,
  onDeleteCallback,
  onHandleClickRowCheckbox,
  onToggleSelectAll,
  onToggleSelectFile,
  selectedFileIds,
  selectedFiles,
  selectedFolder,
}: FilesTableProps) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('file_name');

  const actionBar = document.getElementById('actionBar');
  const actionBarHeight = actionBar ? actionBar.offsetHeight : 0;
  const buttonContainer = document.getElementById('buttonContainer');
  const buttonContainerBottomMargin = buttonContainer
    ? parseInt(getComputedStyle(buttonContainer).marginBottom)
    : 0;
  const divider = document.getElementById('divider');
  const dividerHeight = divider ? divider?.offsetHeight : 0;
  const extraRoom = 1;
  const offsetHeight = actionBarHeight + buttonContainerBottomMargin + dividerHeight + extraRoom;
  const isIndeterminate = selectedFileIds.length > 0 && selectedFileIds.length < files.length;
  const { t } = useTranslation();

  const { formatDate } = useDateFormat();

  function handleSort(column: string) {
    const isAsc = orderBy === column && order === 'asc';

    setOrderBy(column);
    setOrder(isAsc ? 'desc' : 'asc');
  }

  return (
    <Box component={TableContainer} height={`calc(100% - ${offsetHeight}px)`}>
      <Table size="small" stickyHeader>
        {selectedFolder ? (
          <TableHead>
            {areFilesFetching ? (
              [...Array(4)].map((row, index) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <CellSkeleton key={column.code} />
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={files.length > 0 && selectedFileIds.length === files.length}
                    color={isIndeterminate ? 'secondary' : 'primary'}
                    indeterminate={isIndeterminate}
                    onChange={() => onToggleSelectAll()}
                    size="small"
                  />
                </TableCell>

                {columns.map(({ code, text }: { code: string; text: string }) => (
                  <Box component={TableCell} fontWeight="bold" key={code} sortDirection={order}>
                    <TableSortLabel
                      active={code === orderBy}
                      direction={order}
                      onClick={() => handleSort(code)}
                    >
                      {`${t(text)}`}
                    </TableSortLabel>
                  </Box>
                ))}

                <TableCell />
              </TableRow>
            )}
          </TableHead>
        ) : null}

        {!selectedFolder ? (
          <Typography component="caption" sx={{ textAlign: 'center !important' }} variant="button">
            <Typography>
              <Trans i18nKey="select_folder">Please Select a Folder</Trans>
            </Typography>
          </Typography>
        ) : areFilesFetching ? null : !files.length ? (
          <Typography component="caption" sx={{ textAlign: 'center !important' }} variant="button">
            <Trans i18nKey="files_nothing_to_show">Nothing to Show</Trans>
          </Typography>
        ) : (
          <TableBody>
            {(order === 'asc'
              ? sortArrayOfObjectsByKey(files, orderBy)
              : sortArrayOfObjectsByKey(files, orderBy).reverse()
            ).map(
              ({
                can_delete: canDelete,
                date,
                file_name: filename,
                id,
                size,
                thumbnail_url: thumbnailUrl,
                user,
              }: GeneralFile) => (
                <TableRow key={id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedFileIds.includes(id)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onHandleClickRowCheckbox(e, id)
                      }
                      size="small"
                    />
                  </TableCell>

                  <TableCell width="35%">
                    <Box alignItems="center" display="flex">
                      <Box sx={{ flexShrink: 0, mx: 1 }}>
                        <Thumbnail
                          alt={`file thumbnail ${filename}`}
                          size={50}
                          type={''}
                          url={thumbnailUrl}
                        />
                      </Box>

                      <Typography variant="inherit">{filename}</Typography>
                    </Box>
                  </TableCell>

                  <TableCell>{formatBytes(size)}</TableCell>

                  <TableCell> {formatDate(LONG_DATE_FORMAT, date)}</TableCell>

                  <TableCell width="20%">{user}</TableCell>

                  <TableCell>
                    <DownloadButton
                      code="download"
                      fileId={id}
                      onToggleSelectFile={onToggleSelectFile}
                      selectedFiles={selectedFiles}
                      variant="icon"
                    />

                    {canDelete ? (
                      <DeleteButton
                        fileId={id}
                        files={files}
                        location="general-files-file"
                        onDeleteCallback={onDeleteCallback}
                        onToggleSelectAll={onToggleSelectAll}
                        variant="fileIcon"
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        )}
      </Table>
    </Box>
  );
}
