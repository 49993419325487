import { createApi } from '@reduxjs/toolkit/query/react';

import { VisibilityOfSections } from 'src/features/JobForm/JobForm.service';
import { transformFields } from 'src/pages/Job/utilities/helperFunctions';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { isNumber, isObject, isString } from 'src/utilities/helperFunctions2';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

export type BaseRequest = {
  age?: ExpandedAges;
  jobType?: string;
};
export type BaseRequestAndJobId = BaseRequest & JobId;
export type Field = {
  alias: string;
  attr: { class: string };
  desc_en: string;
  feature: {
    SteerAlias: Array<string>;
    parents: Array<string>;
    text?: string;
    labelled_text?: string;
  };
  field_data: Record<string, string> | Options;
  id: number;
  is_disable: boolean;
  is_error: boolean;
  is_hidden: boolean;
  is_mandatory: boolean;
  name: string;
  flags: FieldFlags;
  param: Record<string, string>;
  typ: FieldType;
  [key: `desc_${string}`]: string;
  [key: `name_${string}`]: string;
};
type FieldFlags = {
  job_blocks: boolean;
};
export type Fields = Record<string, Field | undefined>;
export type FieldsTransformed = Record<string, FieldTransformed>;
export type FieldTransformed = Pick<Field, 'alias' | 'id' | 'name'> & {
  attributes?: { classes?: string };
  steer: {
    children?: Array<string>;
    parents?: Array<string>;
  };
  description: string;
  fieldData: Record<string, string> | Options;
  isDisabled: boolean;
  isError: boolean;
  isHidden: boolean;
  isRequired: boolean;
  flags: FieldFlags;
  isLabelledText: boolean;
  isRawText: boolean;
  param: Record<string, string>;
  type: FieldType;
  [key: `desc_${string}`]: string;
  [key: `name_${string}`]: string;
};
export type FieldType =
  | 'bigint'
  | 'bigintnull'
  | 'boolean'
  | 'ccomplete'
  | 'color'
  | 'cselect'
  | 'gselect'
  | 'date'
  | 'ean'
  | 'file'
  | 'image'
  | 'imgpick'
  | 'int'
  | 'mediumint'
  | 'memo'
  | 'newpick'
  | 'pbar'
  | 'pick'
  | 'pickselect'
  | 'resselect'
  | 'rich'
  | 'select'
  | 'smallint'
  | 'string'
  | 'tinyint'
  | 'tradio'
  | 'tselect'
  | 'uselect'
  | 'valselect';
export type FieldValue = boolean | null | number | string | undefined | string[] | [];
export type FieldValues = Record<string, FieldValue>;
type Flag = {
  comment: string;
  name: string;
  reason: string;
};
export type Flags = {
  cancel?: Flag & { code: 'cancel' };
  hold?: Flag & { code: 'onhold' };
};
type CopyJobParams = {
  originJobType?: string;
  originJobId?: number | string;
};
type GetJobFormRequest = BaseRequestAndJobId &
  CopyJobParams & {
    mode?: string;
    archive?: boolean;
  };
type GetTabsRequest = BaseRequestAndJobId;
type Job = {
  fields: Fields;
  sections: VisibilityOfSections;
  values: JobValues;
};
type JobTransformed = Omit<Job, 'fields' | 'sections'> & {
  fields: FieldsTransformed;
  visibilityOfSections: VisibilityOfSections;
};

export type JobIdRequired = Required<JobId>;
export type JobId = {
  jobId?: JobIdValue;
};
export type JobIdValue = number | string;
export type JobIdBackendRequired = Required<JobIdBackend>;
export type JobIdBackend = {
  jobid?: JobIdValue;
};
export type JobValues = FieldValues | OtherJobValues;
export type Option = {
  label: string;
  value: number | string;
};
export type Options = Option[];
export type OtherJobValues = Record<string, Flags | Preview | Tasks> & {
  stichw: string;
  webstatus: string;
};
export type Tab = {
  code: string;
  disabled: boolean;
  link: string;
  name: string;
};
type Task = { comment: string; id: number; name: string; value: string };
export type Tasks = Record<string, Task> | {};
export type Preview = { file_id?: number; url?: string; sub?: string };

export function isOtherJobValues(object?: JobValues): object is OtherJobValues {
  if (!isObject(object) || object === null) return false;

  if (!isString(object.stichw)) return false;

  if (!isNumber(object.webstatus)) return false;

  return true;
}

export const jobApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getJob: build.query<JobTransformed, GetJobFormRequest>({
      providesTags: ['Job'],
      query({ age, archive = false, jobId, jobType, mode, originJobId, originJobType }) {        
        return {
          method: 'GET',
          params: {
            age,
            ...(archive ? { archive: true } : {}),
            form_type: mode,
            origin_jobid: originJobId,
            origin_src: originJobType,
            src: jobType,
          },
          url: `jobs/${jobId}/form`,
        };
      },
      transformResponse({ fields, sections: visibilityOfSections, values }: Job) {
        return { fields: transformFields(fields), values, visibilityOfSections };
      },
    }),
    getTabs: build.query<Tab[], GetTabsRequest>({
      providesTags: ['Tabs'],
      query({ age, jobId, jobType }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType },
          url: '/jobs/gettabs',
        };
      },
    }),
  }),
  reducerPath: 'jobApi',
  tagTypes: ['Job', 'Tabs'],
});

export const { useGetJobQuery, useGetTabsQuery } = jobApi;
