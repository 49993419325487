import axios from 'axios';

import { isObject } from './helperFunctions2';

function formatErrorMessage(error) {
  if (error.config.displayData) {
    return Object.values(error.response.data.data);
  } else {
    return error.response.data.message;
  }
}

export const API = axios.create({
  baseURL: import.meta.env.VITE_API,
  withCredentials: true,
});

API.interceptors.request.use((request) => {
  if (request.data || request.params) {
    const params = request.data || request.params;

    if (params.ignoreCheckingEmptyValue && params.ignoreCheckingEmptyValue === true) {
      request.data
        ? delete request.data['ignoreCheckingEmptyValue']
        : delete request.params['ignoreCheckingEmptyValue'];
    } else {
      Object.keys(params).forEach((key) => {
        if (
          params[key] === '' ||
          params[key] === undefined ||
          params[key] === null ||
          (isObject(params[key]) && !Object.keys(params[key]).length)
        ) {
          request.data ? delete request.data[key] : delete request.params[key];
        }
      });
    }
  }

  return request;
});

export function setupResponseInterceptor({ onNotFound, onUnauthorized }) {
  if (!API.interceptors.response.length) {
    API.interceptors.response.use(
      function (response) {
        if (response.config.onSuccess) {
          response.config.onSuccess(response.data.data);
        }

        return response;
      },
      function (error) {
        if (!axios.isCancel(error)) {
          if (
            (error.response.status === 404 || error.response.status === 403) &&
            error.response.config.method === 'get'
          ) {
            onNotFound();
          } else if (error.response.status === 401) {
            onUnauthorized();
          } else {
            if (error.response.config.onError) {
              error.response.config.onError({
                message: formatErrorMessage(error),
                type: 'error',
              });
            }
          }

          return Promise.reject(error);
        }
      },
    );
  }
}

export function setupRequestInterceptor(interceptor) {
  API.interceptors.request.use(interceptor);
}
