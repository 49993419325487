import { useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { useImageSource } from '../../utilities/hooks';

import defaultThumbnail from '../../images/thumbnails/default_thumbnail.png';
import defaultPdfThumbnail from '../../images/thumbnails/pdf_thumbnail.png';
import defaultZipThumbnail from '../../images/thumbnails/zip_thumbnail.png';

type ThumbnailProps = {
  alt: string;
  fileExtension?: '7z' | 'pdf' | 'rar' | 'zip';
  type: string;
  /** Correlates to the height and width of the thumbnail. e.g. ("100px","100%", 100 )*/
  size: number | string;
  url: string;
};

export function Thumbnail({ alt, fileExtension, size, type, url }: ThumbnailProps) {
  const { imageSource, setImageSource } = useImageSource(url, type);
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const hasExternalLink = type === 'dalim' || type === 'cloudflow';

  function handleFinishLoadingImage() {
    setIsImageLoading(false);
  }

  function handleImageError() {
    setImageSource(
      !fileExtension
        ? defaultThumbnail
        : fileExtension === 'zip' || fileExtension === 'rar' || fileExtension === '7z'
        ? defaultZipThumbnail
        : defaultPdfThumbnail,
    );
    setIsImageError(true);
  }

  return (
    <WaveTooltip
      body={
        !isImageError && !isImageLoading ? (
          <Box
            alt={alt}
            component="img"
            onError={handleImageError}
            onLoad={handleFinishLoadingImage}
            src={imageSource}
          />
        ) : null
      }
      component={
        isImageLoading ? (
          <>
            <Box
              alt={alt}
              component="img"
              onError={handleImageError}
              onLoad={handleFinishLoadingImage}
              src={imageSource}
              sx={{ display: 'none' }}
            />

            <Skeleton height={size} sx={{ cursor: 'default' }} variant="rectangular" width={size} />
          </>
        ) : (
          <Box
            sx={{
              height: size,
              position: 'relative',
              width: size,
            }}
          >
            <Box
              alt={alt}
              component="img"
              onError={handleImageError}
              onLoad={handleFinishLoadingImage}
              src={imageSource}
              sx={{
                cursor: 'default',
                height: size,
                width: size,
              }}
            />

            {hasExternalLink ? (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  opacity: 1,
                  position: 'absolute',
                  top: '0',
                  width: '100%',
                }}
              >
                <WaveIcon
                  code="files_zoom_out_map"
                  fontSize="small"
                  sx={{
                    transitionDuration: '.5s',
                    ...(hasExternalLink && { ':hover': { fontSize: 'x-large', opacity: 0.5 } }),
                  }}
                />
              </Box>
            ) : null}
          </Box>
        )
      }
      placement="right"
      type="custom"
    />
  );
}
