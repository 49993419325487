import { createSlice } from '@reduxjs/toolkit';

import i18n from 'i18next';

const initialState = { messages: [] };

function doesMessageExist(currentMessages, newMessage) {
  return currentMessages.some(
    (currentMessage) =>
      currentMessage.message === newMessage.message && currentMessage.type === newMessage.type,
  );
}

export const waveSnackSlice = createSlice({
  initialState,
  name: 'waveSnack',
  reducers: {
    closeWaveSnack: (state, action) => ({
      messages: state.messages.filter((_, index) => index !== action.payload),
    }),
    openWaveSnack: (state, action) => {
      const newMessage = i18n.t(action.payload.message);
      const newType = action.payload.type;

      if (!doesMessageExist(state.messages, { message: newMessage, type: newType })) {
        return { messages: [...state.messages, { message: newMessage, type: newType }] };
      }
    },
  },
});

export const { closeWaveSnack, openWaveSnack } = waveSnackSlice.actions;

export default waveSnackSlice.reducer;
