import { t as translate } from 'i18next';

import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { TableButtonCell } from 'src/components/TableButtonCell';
import { EmailList } from 'src/features/History/components/EmailList';
import { HistoryTableProps } from 'src/features/History/components/HistoryTable/HistoryTable';
import { ICON_COLOR_MAP } from 'src/features/History/components/HistoryTableRow/utilities/constants';
import { TABLE_CELL_STYLES } from 'src/features/History/components/HistoryTableRow/utilities/styles';
import { GetEmailListResponse } from 'src/features/History/History.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useDateFormat } from 'src/utilities/hooks';
import { JobType } from 'src/utilities/hooks/useRouteParams';

import {
  HistoryItemType,
  HistoryMessageType,
  HistoryMultiEditMessageType,
  HistoryMultiEditValueType,
} from 'src/features/History/History.types';

type RowProps = {
  emailListData?: GetEmailListResponse[];
  hasEmailList: boolean;
  row: HistoryItemType;
} & Omit<HistoryTableProps, 'history'> &
  JobType;

export function HistoryTableRow({
  emailListData,
  expandedHistoryItemIds,
  hasEmailList,
  jobType,
  onToggleExpand,
  row: {
    code,
    datum,
    email_list: emails,
    from_status: fromStatus,
    id,
    message,
    snippet,
    to_status: toStatus,
    type: { text: actionType },
    user,
  },
}: RowProps) {
  const { formatDate } = useDateFormat();
  const formattedDate = formatDate('lib.datetime.long', datum);

  const isExpandable = !!emails || !!message;
  const arrowDirection = expandedHistoryItemIds.includes(id) ? 'up' : 'down';
  const arrowCode = `job-history-table-row-arrow-${arrowDirection}`;
  const actionTypeCodePrefix = 'job-history-table-row-action-type-';
  const shouldDisplayEmails = hasEmailList && emailListData;

  function handleToggleExpand() {
    onToggleExpand(id);
  }

  function renderHistoryItem(msg: HistoryMessageType, key: number) {
    return (
      <Typography key={key} variant="body2">
        {`- ${translate('his.job.changes.format', {
          1: msg.field_name,
          2: msg.old,
          3: msg.new,
        })}`}
      </Typography>
    );
  }

  function renderMultiJobEditHistoryItem(message: HistoryMultiEditMessageType[]) {
    let values: HistoryMessageType[] = [];

    if (message.length === 1) {
      values = message[0]?.value?.map((item: HistoryMultiEditValueType) => {
        return {
          field_name: item.name,
          new: item.value == '' ? '""' : item.value,
          old: '""',
        };
      });
    }

    if (message.length === 2) {
      const beforeChanges = message[0]?.value || [];
      const afterChanges = message[1]?.value || [];

      beforeChanges.forEach((item: HistoryMultiEditValueType) => {
        const itemIndex = afterChanges.findIndex(
          (i: HistoryMultiEditValueType) => i.name === item?.name,
        );

        if (itemIndex >= 0) {
          const afterChangedItem = afterChanges[itemIndex];

          values.push({
            field_name: item.name,
            new: afterChangedItem.value == '' ? '""' : afterChangedItem.value,
            old: item.value == '' ? '""' : item.value,
          });
        }
      });
    }

    return values.map((msg: HistoryMessageType, index: number) => renderHistoryItem(msg, index));
  }

  return (
    <>
      <TableRow>
        {isExpandable ? (
          <TableButtonCell muiTableCellProps={{ sx: TABLE_CELL_STYLES }}>
            <IconButton onClick={handleToggleExpand}>
              <WaveIcon className="primary-txt" code={arrowCode} />
            </IconButton>
          </TableButtonCell>
        ) : (
          <TableCell sx={TABLE_CELL_STYLES} />
        )}

        <TableCell sx={TABLE_CELL_STYLES}>
          <Box alignItems="center" display="flex" justifyItems="center">
            {isNaN(parseInt(code)) ? (
              <WaveIcon
                code={`${actionTypeCodePrefix}${code}`}
                color={ICON_COLOR_MAP[code as keyof typeof ICON_COLOR_MAP] || 'primary'}
                fontSize="small"
              />
            ) : (
              <>
                <StatusIndicator jobType={jobType} label={fromStatus.toString()} size="small" />

                <WaveIcon code={`${actionTypeCodePrefix}status-change`} fontSize="small" />

                <StatusIndicator jobType={jobType} label={toStatus.toString()} size="small" />
              </>
            )}
          </Box>
        </TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{actionType}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{formattedDate}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{user}</TableCell>

        <TableCell sx={TABLE_CELL_STYLES}>{snippet}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={6} sx={{ paddingY: 0 }}>
          <Collapse in={expandedHistoryItemIds.includes(id)} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {code === 'multi-job-edit' ? (
                renderMultiJobEditHistoryItem(message as HistoryMultiEditMessageType[])
              ) : Array.isArray(message) ? (
                message.map((msg, index: number) =>
                  renderHistoryItem(msg as HistoryMessageType, index),
                )
              ) : (
                <Typography variant="subtitle2">{message as string}</Typography>
              )}

              {shouldDisplayEmails ? <EmailList emails={emailListData} /> : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
